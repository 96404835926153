import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import {Layout} from '@components/layout/layout'
import { H1, H3, Paragph } from "@components/layout/style"
import { ListDisc, MainContainer, Question,Response, RightContainer, StyledHero } from "../../components/oferta.style"


const Periodontologia:React.FC = ({data}) => {
  return (<Layout data={data} prop={{
    canonicalUrl: 'https://www.mediroot.pl/oferta/periodontologia/',
    title: 'Periodontologia | Mediroot',
    description: 'Mediroot ➤ Ortodonta Wągrowiec - Leczenie ortodontyczne ✔ Ortodoncja dla dorosłych ✔ Aparaty ortodontyczne ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
  }}>
    <StyledHero>
      <StaticImage
        src="../../assets/images/hero.jpg"
        placeholder="blurred"
        alt="Hero image"
        layout="fullWidth"/>
    </StyledHero>
    <MainContainer>
      <RightContainer>
        <H1>
          Periodontologia - Choroby przyzębia
        </H1>

        <Paragph>
          Periodontologia to dziedzina stomatologii zajmująca się profilaktyką oraz leczeniem chorób przyzębia oraz błony śluzowej jamy ustnej. Przyzębie to nic innego, co zespół tkanek, które otaczają i unieruchamiają ząb, a także mają za zadanie chronić go przed wnikaniem zakażenia z zainfekowanego kanału korzeniowego lub kieszonki dziąsłowej.
        </Paragph>
        <H3>
          Higiena przyzębia
        </H3>

        <Paragph>
          Do zachowania najlepszej higieny przyzębia wymagane jest używanie:
        </Paragph>
        <ListDisc>
          <li>
            szczoteczki do zębów
          </li>
          <li>
            używanie nici dentystycznej
          </li>
          <li>
            regularny skaling nad-dziąsłowy i pod-dziąsłowy w gabinecie u dentysty
          </li>
          <li>
            regularnych płukanek (np. zawierających chlorheksydynę)
          </li>
          <li>
            szczoteczki do przestrzeni między-zębowych (w przypadku chorego przyzębia)
          </li>
        </ListDisc>
        <Paragph>
          Paradontoza to potoczna nazwa zapalenia przyzębia.
          Jest to choroba infekcyjna tkanek przyzębia,
          która nieleczona prowadzi do rozchwiania i utraty zębów.
        </Paragph>
        <Paragph>
          Najczęstszą przyczyną zapalenia przyzębia są bakterie płytki nazębnej i kamienia nazębnego,
           które gromadzące się na powierzchni zębów jako rezultat niedostatecznej higieny przyzębia.
           Mogą one penetrować w głąb kieszonek dziąsłowych (obszar między brzegiem dziąsła, a tkanką zęba)
           i tworzyć kieszenie patologiczne, a w rezultacie powodować zanik kości i rozchwianie zębów,
           co bezpośrednio prowadzi do ich wypadania. Niewielką rolę odgrywają również czynniki genetyczne.
           W przypadku wolnego systemu immunologicznego organizmu ryzyko zapalenia jest mniejsze, nawet pomimo przewlekłego zapalenia dziąseł.
           Choroba ta jest rozpoznawana zarówno u dzieci, młodzieży, jak też osób dorosłych.
           Dane epidemiologiczne wykazały, że 50% 7-latków, 75% 12-latków i 99% dorosłych ma chore przyzębie, co sprawia,
           że częstość występowania tych schorzeń kwalifikuje się do grupy chorób społecznych.
           Postępowanie chorób przyzębia można zatrzymać.
           Przyczynami chorób przyzębia są:
        </Paragph>
        <ListDisc>
          <li>
            bakteryjna płytka nazębna spowodowana złą higieną jamy ustnej
          </li>
          <li>
            palenie tytoniu
          </li>
          <li>
            nadmierne spożywanie alkoholu
          </li>
          <li>
            bruksizm (zgrzytanie zębami)
          </li>
          <li>
            zaburzenia hormonalne
          </li>
          <li>
            zaburzenia ogólnoustrojowe (np. cukrzyca)
          </li>
          <li>
            nieprawidłowa dieta
          </li>
          <li>
            wadliwe wypełnienia
          </li>
          <li>
            nieprawidłowe uzupełnienia protetyczne (stare uciskające protezy, nieszczelne korony i mosty)
          </li>
          <li>
            awitaminoza
          </li>
          <li>
            zaburzenia wydzielania śliny
          </li>
        </ListDisc>

        <Paragph>
        Za najgroźniejszy czynnik mający wpływ na rozwój paradontozy uważa się płytkę nazębną,
        która po pewnym czasie przeradza się w kamień nazębny.
        </Paragph>
        <Paragph>
          Pierwsze objawy to obnażanie szyjek zębów przy jednoczesnym zapaleniu dziąseł.
          Przebieg choroby charakteryzuje się sukcesywną utratą kości wyrostka zębodołowego,
          a przez to utratą aparatu więzadłowego zęba.
          W ostrych stanach zapalnych pojawia się ból i krwawienie dziąseł.
          Zabiegi stosowane w leczeniu to:
        </Paragph>
        <ListDisc>
          <li>
            skaling
          </li>
          <li>
            nauka właściwej higieny
          </li>
          <li>
            usuwane czynników drażniących (nawisy na wypełnieniach, stare protezy)
          </li>
          <li>
            szynowanie zębów
          </li>
          <li>
            przeszczepy dziąsła
          </li>
          <li>
            augumentacja kości
          </li>
        </ListDisc>

        <Paragph>
        Toczący się w przyzębiu proces zapalny prowadzi do uszkodzenia jego tkanek, nierzadko powodując utratę kilku, a nawet wszystkich zębów. Nieleczenie pionowych ubytków kości wyrostka zębodołowego prowadzi do poważnego upośledzenia funkcji narządu żucia. Chirurgia periodontologiczna jest istotnym uzupełnieniem ogólnego kompleksowego leczenia zapaleń przyzębia. Dzięki niej otwierają się szanse przywrócenia zębom ich tkanek podporowych. Nowoczesne metody leczenia chirurgicznego pozwalają zatem uniknąć niepotrzebnych ekstrakcji zębów oraz rozszerzają możliwości rehabilitacji narządu żucia.
        </Paragph>

        <H3>
          Usuwanie kamienia nazębnego
        </H3>

        <Paragph>
          Kamień nazębny składa się ze zwapniałych warstw płytki nazębnej. Początkowo jego odkładanie na zębach przebiega bezobjawowo, lecz w miarę powiększania swych wymiarów może powodować wiele chorób m.in. zapalenie i krwawienie dziąseł, próchnicę, rozchwianie zębów. Aby tego uniknąć należy dokładnie czyścić zęby z płytki nazębnej oraz regularnie usuwać kamień w gabinecie stomatologicznym. Rozróżniamy kamień nad- i poddziąsłowy.
        </Paragph>

        <H3>
          Pytania
        </H3>

        <Paragph style={{textIndent:0}}>
          <Question>
            Czy usuwanie kamienia uszkadza szkliwo?
          </Question>
          <Response>
            Prawidłowo przeprowadzony skaling złogów kamienia nazębnego nie jest szkodliwy dla szkliwa.
          </Response>
          <Question>
            Jak często należy usuwać kamień?
          </Question>
          <Response>
            Częstotliwość usuwania kamienia nazębnego jest zależna od poziomu higieny jamy ustnej oraz od składu śliny – im więcej związków mineralnych zawiera ślina tym większe jest odkładanie.
          </Response>
        </Paragph>

      </RightContainer>
    </MainContainer>


  </Layout>);
};

export default Periodontologia;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
